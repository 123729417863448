import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import { RegisterForm, RegistrationType } from "../components/register-form"

const RegisterPage = () => {
  return (
    <Layout>
      <SEO title="THACO | Register" name="register-unofficial" />
      <Header page="register" />
      <section className="pb-5 text-white" style={{ paddingTop: 150 }}>
        <div className="container" style={{ maxWidth: 800 }}>
          <h2 className="text-center mb-5">
            <span className="bg-dark px-2 py-1">สมัครเข้าร่วมแข่งขัน</span>
          </h2>
          <RegisterForm type={RegistrationType.UNOFFICIAL} />
        </div>
      </section>
      <Footer />
    </Layout>
  )
}

export default RegisterPage
